import axios from "axios";
import wx from "weixin-js-sdk";
export function wxConfig(to) {
  let u = navigator.userAgent;
  let url = "";
  if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    url =
      window.utils.storage.getter("firstUrl", 1) ||
      `${window.location.origin}${to.fullPath}`;
  } else {
    url = `${window.location.origin}${to.fullPath}`;
  }

  let data = {
    url,
  };
  axios
    .post("patient/weixin/share", data)
    .then((res) => {
      let data = res.data;
      let user_version = window.utils.storage.getter("user_version", 2);
      if (!user_version) {
        window.utils.storage.setter("user_version", data.user_version, 2);
      } else if (data.user_version && user_version != data.user_version) {
        window.utils.storage.setter("user_version", data.user_version, 2);
        location.reload();
      }
      wx.config({
        debug: false,
        appId: data.appId,
        timestamp: data.timestamp,
        nonceStr: data.nonceStr,
        signature: data.signature,
        jsApiList: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "getLocation",
        ],
        openTagList: ["wx-open-launch-weapp"],
      });
      wx.error(function(err) {
        console.log("切换路由时jssdk授权失败");
        console.log(err);
      });
      wx.ready(() => {
        let imgUrl = "http://q.xlanben.com/images/logo.png",
          title = "歧黄医官，您的移动私人医生",
          desc = "便捷健康咨询、就诊用药指导，为您和家人的健康护航",
          link = window.location.origin + "/index";
        wx.updateAppMessageShareData({
          title,
          desc,
          link,
          imgUrl,
        });
        wx.updateTimelineShareData({
          title,
          link,
          imgUrl,
        });
        wx.hideMenuItems({
          menuList: [
            "menuItem:editTag",
            "menuItem:delete",
            "menuItem:copyUr",
            "menuItem:originPage",
            "menuItem:readMode",
            "menuItem:openWithQQBrowser",
            "menuItem:openWithSafari",
            "menuItem:share:email",
            "menuItem:share:brand",
          ],
        });
      });
    })
    .catch((res) => {
      console.log(res);
    });
}
